<template>
  <a-drawer
    title="代理商详情"
    placement="right"
    :width="drawerWidth"
    class="myDescription"
    :closable="false"
    :visible="visible"
    :after-visible-change="afterVisibleChange"
    @close="handleCancel"
  >
    <a-card :bordered="false">
      <a-spin :spinning="loading">
        <a-descriptions title="基本信息">
          <a-descriptions-item label="代理商名称" span="3">{{ model ? model.name : '暂无信息' }}</a-descriptions-item>
          <a-descriptions-item label="所在地区" span="3">{{
            model ? model.area.join('') : '暂无信息'
          }}</a-descriptions-item>
          <a-descriptions-item label="创建时间" span="3">{{
            model ? model.createTime : '暂无信息'
          }}</a-descriptions-item>
        </a-descriptions>
        <a-table :columns="columns" :data-source="resident"></a-table>
      </a-spin>
    </a-card>
  </a-drawer>
</template>

<script>
import { agentGet, houseAgentQueryResident } from '@/api/agent'
export default {
  data() {
    return {
      noticeTitle: '代理商管理',
      visible: false,
      loading: false,
      id: null,
      houseId: null,
      model: null,
      resident: [],
      columns: [
        {
          title: '驻场',
          dataIndex: 'residentName'
        },
        {
          title: '手机号',
          dataIndex: 'residentPhone'
        }
      ]
    }
  },
  created() {
    this.houseId = this.$route.query.id
  },
  methods: {
    showModal(record) {
      console.log(record, 'record');
      this.visible = true
      this.id = record.agentId
      this.fetchAgent()
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.model = null
    },
    afterVisibleChange(val) {
      console.log('visible', val)
    },
    fetchAgent() {
      this.loading = true
      agentGet(this.id).then(res => {
        if (res.id) {
          console.log(res, 'agent res')
          this.model = res
          this.model.area = [res.province, res.city, res.district, res.street]
          this.fetchAgentResident()
        }
      })
    },
    fetchAgentResident() {
      const param = {
        agentId: this.id,
        houseId: this.houseId,
        ...this.defaultPagination
      }
      houseAgentQueryResident(param)
        .then(res => {
          if (res.success) {
            const len = res.data.content.length
            this.resident = len === 0 ? [] : res.data.content[0].houseResidentDtoList
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
