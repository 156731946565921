<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="代理商名称">
              <a-input v-model="queryParam.agentName" allow-clear placeholder="请输入代理商名称" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-form-item label="代理商编码">
              <a-input v-model="queryParam.agentCode" allow-clear placeholder="请输入代理商编码" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button style="margin-left: 8px" @click="tableReset">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="addRow">添加</a-button>
      <a-button type="danger" icon="delete" @click="deleteRow">移除</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :rowKey="record => record.id"
      :columns="columns"
      :data="loadData"
      showPagination="auto"
      :rowSelection="rowSelection"
      :totalCount="totalCount"
      :alert="true"
    >
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="lookInfo(record)">查看</a>
        </template>
      </span>
      <span slot="person" slot-scope="text, record">
        <a-tag color="blue" class="myPointer" @click="lookPerson(record)">查看</a-tag>
      </span>
    </s-table>

    <agent-create ref="agentCreate" @reload="reload" />
    <agent-drawer ref="agentDrawer"></agent-drawer>
    <person-drawer ref="personDrawer"></person-drawer>
    <agent-delete ref="agentDelete" :selectedRows="selectedRows" @reload="delReload"></agent-delete>
  </a-card>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { houseAgentQuery } from '@/api/agent'
import AgentCreate from './modules/AgentCreate'
import AgentDrawer from './modules/AgentDrawer'
import PersonDrawer from './modules/PersonDrawer'
import AgentDelete from './modules/AgentDelete'

const columns = [
  {
    title: '代理商名称',
    dataIndex: 'agentName'
  },
  {
    title: '企业代码',
    dataIndex: 'agentCode'
  },
  {
    title: '所在地区',
    dataIndex: 'area'
  },
  {
    title: '详细地址',
    dataIndex: 'address'
  },
  {
    title: '人员',
    dataIndex: 'person',
    scopedSlots: { customRender: 'person' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'Agent',
  components: {
    STable,
    Ellipsis,
    AgentCreate,
    PersonDrawer,
    AgentDrawer,
    AgentDelete
  },
  data() {
    this.columns = columns
    return {
      totalCount: 0,
      noticeTitle: '代理商',
      houseId: null,
      houseLink: null,
      // 查询参数
      queryParam: {},
      // 删除
      selectedRowKeys: [],
      selectedRows: [],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const param = {
          houseId: this.houseId
        }
        const params = this.paramFormat(parameter, param)
        Object.assign(params, this.queryParam)
        return houseAgentQuery(params).then(res => {
          const result = this.resFormat(res)
          result.data.forEach(item => {
            item.area = []
            item.area.push(item.agentProvince)
            item.area.push('-' + item.agentCity)
            item.area.push('-' + item.agentDistrict)
            item.area.push('-' + item.agentStreet)
          })
          this.totalCount = result.totalCount
          return result
        })
      }
    }
  },
  watch: {},
  created() {
    this.houseId = this.$route.query.id
    this.houseLink = this.$route.query.link
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    serial() {
      return this.$store.state.env.serial
    },
    host() {
      return this.$store.state.oss.host
    }
  },
  methods: {
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    delReload() {
      this.selectedRows = []
      this.$refs.table.refresh()
    },
    addRow() {
      this.$refs.agentCreate.showModal()
    },
    editInfo(record) {
      this.$refs.agentCreate.showModal(record)
    },
    lookInfo(record) {
      this.$refs.agentDrawer.showModal(record)
    },
    lookPerson(record) {
      this.$refs.personDrawer.showModal(record)
    },
    deleteRow() {
      const len = this.selectedRows.length
      console.log(this.selectedRows, 'roiw')
      if (len <= 0) {
        this.$message.warning('请选择要删除的项', 2)
      } else {
        this.$refs.agentDelete.showModal()
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    tableReset() {
      this.queryParam = {}
      this.$refs.table.refresh()
    }
  }
}
</script>
