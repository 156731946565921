<template>
  <div>
    <a-modal
      :title="noticeTitle"
      :visible="visible"
      :confirm-loading="loading"
      :maskClosable="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <p>让这些驻场成为代理商“{{ record ? record.agentName : '-' }}”的驻场？</p>
    </a-modal>
  </div>
</template>

<script>
import { houseAgentAddResident } from '@/api/agent'
export default {
  name: 'DeleteModal',
  props: {
    residents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      noticeTitle: '添加代理商驻场',
      record: {},
      visible: false,
      loading: false
    }
  },
  methods: {
    showModal(record) {
      this.record = record
      this.visible = true
    },
    handleOk() {
      this.loading = true
      const houseAgentId = this.record.id
      const residentIds = this.residents
        .map(item => {
          console.log(item)
          const value = item.key.split(',')
          const id = value[1]
          return id
        })
        .join(',')
      const param = [{
        houseAgentId,
        residentIds
      }]
      houseAgentAddResident(param)
        .then(res => {
          if (res.success) {
            this.visible = false
            this.$notification.success({ message: this.noticeTitle, description: '成功' })
            this.$emit('reload')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleCancel() {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped></style>
