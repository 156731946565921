<template>
  <div>
    <a-modal
      :width="modalWidth"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-spin :spinning="loading">
        <a-form :form="form" v-bind="formLayout">
          <a-form-item label="代理商">
            <a-select
              allow-clear
              mode="multiple"
              label-in-value
              placeholder="选择代理商"
              style="width: 100%"
              @change="handleChange"
              option-label-prop="label"
              v-decorator="['agent', { rules: [{ required: true, message: '请选择代理商' }] }]"
            >
              <a-select-option v-for="item in fetch" :key="item.value" :value="item.value" :label="item.name">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import { houseAgentAdd, houseAgentQuery, agentQuery } from '@/api/agent'
const fields = ['agent']

export default {
  data() {
    return {
      noticeTitle: '代理商管理',
      houseId: null,
      visible: false,
      loading: false,
      id: null,
      fetch: [],
      current: [],
      form: this.$form.createForm(this)
    }
  },
  created() {
    this.houseId = this.$route.query.id
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    showModal() {
      this.visible = true
      this.loadData()
    },
    handleOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.loading = true
          const agentIds = values.agent
            .map(item => {
              return item.key.split(',')[1]
            })
            .join(',')
          const param = { houseId: this.houseId, agentIds }
          this.newInfo(param)
        } else {
          this.loading = false
        }
      })
    },
    handleCancel() {
      this.form.setFieldsValue({
        agent: []
      })
      this.visible = false
    },
    handleChange(value) {
      this.form.setFieldsValue({
        agent: value
      })
    },
    loadData() {
      this.loading = true
      this.form.setFieldsValue({
        agent: []
      })
      const param = { houseId: this.houseId, ...this.maxPagination }
      houseAgentQuery(param)
        .then(res => {
          if (res.success) {
            this.current = res.data.content
            this.loadAgent()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadAgent() {
      const param = { ...this.maxPagination }
      agentQuery(param).then(res => {
        if (res.success) {
          const allData = res.data.content.map(item => {
            return {
              name: item.name,
              text: `${item.name}(${item.code})`,
              value: item.name + ',' + item.id,
              code: item.code
            }
          })
          const allId = allData.map(item => {
            const value = item.value.split(',')
            return value[1]
          })
          const currentId = this.current.map(item => item.agentId)
          const arr = []
          allId.filter((item, index) => {
            if (!currentId.includes(item)) {
              arr.push(allData[index])
            }
          })
          this.fetch = arr
        }
      })
    },
    newInfo(param) {
      setTimeout(() => {
        if (this.visible) {
          houseAgentAdd(param)
            .then(res => {
              if (res.success) {
                this.visible = false
                this.$emit('reload')
                this.$notification.success({ message: this.noticeTitle, description: '添加成功' })
              }
            })
            .finally(() => {
              this.loading = false
            })
        }
      }, this.asyncTime)
    },
    onChange(value) {
      console.log(value, 'city')
    }
  }
}
</script>
